import "./productList.scss";
import { useState } from "react";
import { products } from "../../constants/data";
import Product from "../product/Product";

const ProductList = () => {
  const [listedProducts, setListedProducts] = useState(true);

  const toggleListedProjects = () => {
    setListedProducts((prev) => !prev);
  };
  return (
    <div className="pl gradient__bg" id="projects">
      <div className="pl-text">
        <h2
          className="pl-title"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-anchor-placement="top-bottom"
        >
          {"<"}Demo Projects{">"}
        </h2>
        {/* <p className="pl-desc">[Create & Inspire]</p> */}
      </div>
      <div className="pl-list">
        {products.slice(0, listedProducts ? 1 : 2).map((item) => (
          <Product
            key={item.id}
            id={item.id}
            img={item.img}
            avatar={item.avatar}
            uml={item.uml}
            link={item.link}
            code={item.code}
            stack={item.stack}
            title={item.title}
            info1={item.info1}
            info2={item.info2}
            info3={item.info3}
            info4={item.info4}
          />
        ))}
      </div>
      {listedProducts && (
        <>
        <button
          className="pl__button-title"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-anchor-placement="top-bottom"
          onClick={toggleListedProjects}
          >
          Let's See More {"</"}Demo Projects{">"}
        </button>
          </>
      )}
      {!listedProducts && (
        <a href="#projects"><button
          className="pl__button-title"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-anchor-placement="top-bottom"
          onClick={toggleListedProjects}
        >
          Just Show the Best {"</"}Demo Project{">"}
        </button></a>
      )}
    </div>
  );
};

export default ProductList;
