import React, { useState } from "react";
import { Flex, Avatar, Card, Modal } from "antd";

const { Meta } = Card;

const ProjectCard = ({
  id,
  avatar,
  uml,
  link,
  code,
  title,
  stack,
  info1,
  info2,
  info3,
  info4,
  current,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="app-details">
      {current === "console" ? (
        <div className="console">Console was cleared</div>
      ) : null}
      <Flex gap="middle" align="start" vertical>
        <Card
          hoverable
          style={{ minWidth: 150 }}
          className="card__project"
          cover={
            id === "0-mmstore-1" && (
              <img alt="UML diagram" src={uml} onClick={showModal} />
            )
          }
        >
          <Modal
            title="UML diagram of the application"
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={829}
          >
            <img alt="UML diagram" src={uml} />
          </Modal>
          <Meta
            avatar={
              <Avatar src={avatar} />
            }
            title={title}
            description="About the application"
          />
          <p className="p-info">
            {" "}
            <span>{">"} </span> {info1}{" "}
          </p>
          <p className="p-info">
            {" "}
            <span>{">"} </span> {info2}{" "}
          </p>
          <p className="p-info">
            {" "}
            <span>{">"} </span> {info3}{" "}
          </p>
          <p className="p-info">
            {" "}
            <span>{">"} </span> {info4}{" "}
          </p>
          <p className="stack p-info">
            {" "}
            <span>{">"} </span> {stack}{" "}
          </p>
          <div className="foot__links">
            <a href={link} target="_blank" rel="noreferrer">
              <div className="foot__links_demo_sm">Live Demo Application</div>
            </a>
            <a href={code} target="_blank" rel="noreferrer">
              <div className="foot__links_code_sm">View Code on GitHUB</div>
            </a>
          </div>
        </Card>
      </Flex>
    </div>
  );
};

export default ProjectCard;
