import "./skills.css";
import { skillset } from "../../constants/skillset";
import CardList from "./CardList";
// import { useEffect, useRef } from "react";

function Skills() {
  // const elementRef = useRef(null);

  // useEffect(() => {
  //   const element = elementRef.current;
  //   if (!element) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           const ele = entry.target;
  //           const src = ele.getAttribute("data-background-srcset");
  //           ele.removeAttribute("data-background-srcset");
  //           // Update the `background-image` style
  //           ele.style.backgroundImage = `url(${src})`;

  //           observer.unobserve(ele);
  //         }
  //       });
  //     },
  //     {
  //       threshold: 0,
  //     }
  //   );
  //   observer.observe(element);

  //   return () => {
  //     observer.unobserve(element);
  //   };
  // }, []);
  return (
    <>
      <div className="skills tc" id="skills">
        <div className="s-container">
          <h2 className="s-title">
            <span
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="300"
              data-aos-anchor-placement="top-bottom"
            >
              Main stack
            </span>
          </h2>
          {/* <p className="s-desc white">[Main Web Development Skills & Tools]</p> */}
          <div
            className="s-wrapper"
            // ref={elementRef}
            // data-background-src={`https://ychef.files.bbci.co.uk/1600x900/p01wjqwx.webp`}
            // data-background-srcset={`https://ychef.files.bbci.co.uk/400x225/p01wjqwx.webp 400w, https://ychef.files.bbci.co.uk/600x337/p01wjqwx.webp 600w, https://ychef.files.bbci.co.uk/800x450/p01wjqwx.webp 800w, https://ychef.files.bbci.co.uk/1200x675/p01wjqwx.webp 1200w,https://ychef.files.bbci.co.uk/1600x900/p01wjqwx.webp 1600w, https://ychef.files.bbci.co.uk/3200x1800/p01wjqwx.webp 3200w`}
          >
            <div className="skillset">
              <CardList skillset={skillset} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skills;
