import "./product.scss";
import "./devtools.scss";
import { useState } from "react";
// import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
// import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import {
  PlaySquareOutlined,
  CodeOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import ProjectCard from "./ProjectCard";

const Product = ({
  id,
  img,
  avatar,
  uml,
  link,
  code,
  title,
  stack,
  info1,
  info2,
  info3,
  info4,
}) => {
  const [current, setCurrent] = useState("elements");
  const items = [
    {
      label: "Elements",
      key: "elements",
      icon: <CodeOutlined />,
    },
    {
      key: "console",
      icon: <GithubOutlined />,
      label: (
        <a href={code} target="_blank" rel="noopener noreferrer">
          Console
        </a>
      ),
    },
    {
      key: "app",
      icon: <PlaySquareOutlined />,
      label: (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Application
        </a>
      ),
    },
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  return (
    <>
      <div className="projects">
        <div className="p-browser">
          <div className="p-circle"></div>
          <div className="p-circle"></div>
          <div className="p-circle"></div>
          <div className="p-title">{title}</div>
        </div>
        <a href={link} target="_blank" rel="noreferrer">
          <img src={img} alt="" className="p-img" />
        </a>
      </div>
      <div
        className="p-devtools"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-easing="ease-out-cubic"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="p-dev-tools">
          <div className="p-dev-browser">
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
            {/* <HighlightAltIcon className="icon" />
          <AddToHomeScreenIcon className="icon" /> */}
            {/* <span className="arrow">{" >>"}</span> */}
          </div>
          <ProjectCard
            id={id}
            img={img}
            avatar={avatar}
            uml={uml}
            link={link}
            code={code}
            title={title}
            stack={stack}
            info1={info1}
            info2={info2}
            info3={info3}
            info4={info4}
            current={current}
          />
        </div>
      </div>
    </>
  );
};

export default Product;
