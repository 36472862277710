import { list } from "./list";

import logo from "../../img/logo.svg";
import linkedin from "../../img/linkedin.svg";
import github from "../../img/github.svg";

import {
  FooterContainer,
  LinksContainer,
  LinksLogoContainer,
  LinksDivContainer,
  IconsContainer,
  IconContainer,
  CopyrightContainer,
} from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <LinksContainer>
        <LinksLogoContainer>
          <img src={logo} alt="Andras Varga logo" />
          <h3>ANDRAS VARGA</h3>
          <p>Software Developer</p>
        </LinksLogoContainer>
        <LinksDivContainer>
          <h4>NAVIGATION</h4>
          {list.map((list) => {
            return (
              <a key={list.id} href={list.link}>
                <p>{list.name}</p>
              </a>
            );
          })}
        </LinksDivContainer>
        <LinksDivContainer>
          <h4>Get in touch</h4>
          <p>Budapest</p>
          <p>+36-1-xxx-xxxx</p>
        </LinksDivContainer>
        <IconsContainer>
          <a
            href="https://www.linkedin.com/in/vargaae/"
            target="_blank"
            rel="noreferrer"
          >
            <IconContainer>
              <img src={linkedin} alt="linkedin logo" />
            </IconContainer>
          </a>
          <a href="#contact">
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                viewBox="0 0 24 24"
                color="rgb(255, 255, 255)"
              >
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
              </svg>
            </IconContainer>
          </a>
          <a href="https://github.com/vargaae" target="_blank" rel="noreferrer">
            <IconContainer>
              <img src={github} alt="github logo" />
            </IconContainer>
          </a>
        </IconsContainer>
      </LinksContainer>

      <CopyrightContainer>
        <p>Ⓒ Andras Varga's Portfolio 2025</p>
        <p>All rights reserved</p>
      </CopyrightContainer>
    </FooterContainer>
  );
};

export default Footer;
