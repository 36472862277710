import "./intro.scss";
import IntroImg from "../../img/intro.jpg";
import Logo from "../../img/logo.svg";

export default function Intro() {
  return (
    <div className="outro" id="about">
      <div className="intro-left">
        <div className="intro-left-wrapper">
          <a href="#contact">
            <h3 data-aos="flip-left" data-aos-delay="200"  className="outro-getintouch">Get in touch.</h3>
            <h4 data-aos="flip-left" data-aos-delay="300"  className="outro-sendemail">Send me an email</h4>
            <h5 data-aos="flip-left" data-aos-delay="400" className="outro-getintouch">
          'My goal is to create innovative and impactful desktop and web solutions that solve
          real-world problems and make a difference.' 
        </h5>
            {/* <div className="intro-skills">
              <div className="intro-skills-wrapper">
                <div className="intro-skills-item">Python</div>
                <div className="intro-skills-item">Javascript</div>
                <div className="intro-skills-item">NodeJS</div>
                <div className="intro-skills-item">React</div>
                <div className="intro-skills-item">NextJS</div>
                <div className="intro-skills-item">Web Developer</div>
              </div>
            </div> */}
          </a>
        </div>
        <img src={Logo} alt="" className="intro-logo" />
      </div>
      <div className="intro-right">
        <div className="intro-bg"></div>
        <img src={IntroImg} alt="" className="intro-img" />
      </div>
    </div>
  );
}
