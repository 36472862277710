import React, { useState, useEffect } from "react";

// import Navbar from "./components/navbar/Navbar";
import Topbar from "./components/topbar/Topbar";
import Top from "./components/top/Top";
import Header from "./components/header/Header";
// import Start from "./components/start/Start";
import Intro from "./components/intro/Intro";
// import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import ProductList from "./components/productList/ProductList";
// import PythonProjects from "./containers/pythonProjects/PythonProjects";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import "./app.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import Menu from "./components/menu/Menu";

function App() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  // const [navGradient, setNavGradient] = useState("");

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="app backpattern">
            {/* <nav
        className="gradient__bg"
        style={{
          backgroundColor: navColor,
          height: navSize,
          transition: "all 1s",
        }}
      >
        <Navbar navGradient={navGradient} />
      </nav> */}
      <Top menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Header />
      <Skills />
      {/* <Start /> */}
      <div className="gradient__bg">
        <ProductList />
      </div>
      {/* <PythonProjects /> */}
      {/* <About /> */}
      <Intro />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
