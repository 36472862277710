import React from "react";

const Card = ({ name, badge, id }) => {
  return (
    <div
      className="tc dib br3 pa3 ma1 grow bw2 shadow-5"

      style={{ width: "160px" }}
    >
    <div
      data-aos-delay={id}
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-easing="ease-out-cubic"
      data-aos-anchor-placement="top-bottom"
    >
      <img src={badge} alt={name} style={{ width: "60px" }} />
      <div>
        <h2 className="s-desc white">{name}</h2>
      </div>
    </div>
    </div>
  );
};

export default Card;
